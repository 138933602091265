// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-chiva-js": () => import("../src/pages/about-chiva.js" /* webpackChunkName: "component---src-pages-about-chiva-js" */),
  "component---src-pages-buy-chiva-beans-js": () => import("../src/pages/buy-chiva-beans.js" /* webpackChunkName: "component---src-pages-buy-chiva-beans-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-cancelled-js": () => import("../src/pages/order-cancelled.js" /* webpackChunkName: "component---src-pages-order-cancelled-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

